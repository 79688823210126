import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Fab,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Base } from "common/Base";
import { CustomBreadCrumbs } from "common/BreadCrumbs";
import { ContentContainer } from "common/ContentContainer";
import { TabNames } from "common/NavBar";
import { NavBar } from "common/NavBar";
import { PageTitle } from "common/Titles";
import { Poll, PollAssignment, Pollster } from "common/apiTypes";
import { useModal } from "common/hooks";

import { PaginatedNav } from "components/PaginatedNav";
import {
  PollAssignmentModal,
  PollsterSearchModal,
} from "components/PollAssignmentModal";

import { PollAssignmentRepository } from "repositories/PollAssignmentRepository";
import { useGetPaginated } from "repositories/utils";

export { PollAssignmentPage };

interface PollsterTableProps {
  pollAssignments: PollAssignment[];
  onDelete: () => Promise<void>;
  onEdit: (pollAssignment: PollAssignment) => Promise<void>;
}

function PollAssignmentTable({
  pollAssignments,
  onDelete,
  onEdit,
}: PollsterTableProps): JSX.Element {
  const navigate = useNavigate();
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#DD6B25" }}>
            <TableRow>
              <TableCell align="center">Ver</TableCell>
              <TableCell align="center">Usuario</TableCell>
              <TableCell align="center">Nombre</TableCell>
              <TableCell align="center">Respuestas</TableCell>
              <TableCell align="center">Editar</TableCell>
              <TableCell align="center">Eliminar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pollAssignments.map((pollAssignment) => (
              <TableRow
                key={pollAssignment.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  sx={{ color: "#DD6B25" }}
                >
                  <IconButton
                    onClick={() => {
                      navigate(`/pollster/${pollAssignment.pollster.id}`);
                    }}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  {pollAssignment.pollster.user.username}
                </TableCell>
                <TableCell align="center">
                  {pollAssignment.pollster.name}{" "}
                  {pollAssignment.pollster.last_name}{" "}
                </TableCell>
                <TableCell align="center">0</TableCell>
                <TableCell align="center">
                  <EditIcon
                    onClick={() => {
                      onEdit(pollAssignment);
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <ClearIcon
                    onClick={async () => {
                      await PollAssignmentRepository.deletePollAssignment(
                        pollAssignment.id
                      );
                      await onDelete();
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const defaultSearchParams = new URLSearchParams();
defaultSearchParams.set("poll", "-1");

function PollAssignmentPage(): JSX.Element {
  const { id: pollId } = useParams();
  const [selectedPollAssignment, setSelectedPollAssignment] = useState<
    PollAssignment | undefined
  >();
  const [selectedPollster, setSelectedPollster] = useState<
    Pollster | undefined
  >();
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const [pollAssignments, refreshPollAssignments] =
    useGetPaginated<PollAssignment>(
      PollAssignmentRepository.getPollAssignments,
      searchParams
    );

  const secondModal = useModal(async () => {
    await refreshPollAssignments();
  });

  const searchModal = useModal(() => {
    secondModal.setShowModal(true);
  });

  useEffect(() => {
    const newUrlSearchParams = new URLSearchParams();
    newUrlSearchParams.set("poll", pollId ?? "-1");
    setSearchParams(newUrlSearchParams);
  }, [pollId]);

  return (
    <Base>
      <NavBar currentTab={TabNames.Encuestas} />
      <ContentContainer>
        <CustomBreadCrumbs
          breadcrumbs={[{ label: "Encuestas", targetUrl: "/polls" }]}
        />
        <PageTitle title={"Asignar Encuestadores"} />
        <PollAssignmentTable
          pollAssignments={pollAssignments.results}
          onDelete={async () => {
            await refreshPollAssignments();
          }}
          onEdit={async (pollAssignment) => {
            setSelectedPollster(pollAssignment.pollster);
            setSelectedPollAssignment(pollAssignment);
            secondModal.setShowModal(true);
          }}
        />
        <PaginatedNav
          page={pollAssignments.page}
          totalPages={pollAssignments.total_pages}
        />
        <Fab
          color="primary"
          variant="extended"
          aria-label="add"
          sx={{ marginTop: "2rem" }}
          onClick={() => searchModal.setShowModal(true)}
        >
          <AddIcon sx={{ marginRight: "0.5rem", verticalAlign: "middle" }} />
          AÑADIR
        </Fab>
        <PollsterSearchModal
          handleConfirm={searchModal.handleConfirmModal}
          handleClose={searchModal.handleCloseModal}
          show={searchModal.showModal}
          selectedPollster={selectedPollster}
          setSelectedPollster={setSelectedPollster}
        />
        <PollAssignmentModal
          handleConfirm={secondModal.handleConfirmModal}
          handleClose={secondModal.handleCloseModal}
          show={secondModal.showModal}
          pollster={selectedPollster}
          pollId={pollId}
          pollAssignment={selectedPollAssignment}
        />
      </ContentContainer>
    </Base>
  );
}
