import { CustomTextField } from "../common/formUtilities";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Formik } from "formik";
import React from "react";
import * as yup from "yup";

export { RangeForm };

const schema = yup.object().shape({
  minimum: yup.number().default(1).min(0),
  maximum: yup.number().default(5).max(100),
});

function RangeForm(): JSX.Element {
  return (
    <form
      css={{
        width: "100%",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <CustomTextField name="minimum" label="Mínimo" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField name="maximum" label="Máximo" />
        </Grid>
      </Grid>
    </form>
  );
}

export { RangeModal };
interface RangeModalProps {
  handleClose?: () => void;
  handleConfirm?: () => void;
  valueRef: React.Ref<any>;
  show: boolean;
}

function RangeModal({
  handleClose,
  handleConfirm,
  valueRef,
  show,
}: RangeModalProps): JSX.Element {
  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        borderRadius: "1rem",
      }}
    >
      <Fade in={show}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItem: "flex-start",
            justifyContent: "flex-start",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "600px",
            bgcolor: "background.paper",
            borderRadius: "1rem",
            p: 4,
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Rango
          </Typography>
          <Formik
            validationSchema={schema}
            validateOnChange={false}
            initialValues={schema.getDefault()}
            innerRef={valueRef}
            onSubmit={async () => {}}
          >
            {(props) => <RangeForm />}
          </Formik>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: "2rem",
              alignSelf: "flex-end",
            }}
          >
            <Button variant="contained" disableRipple onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="contained" disableRipple onClick={handleConfirm}>
              Confirmar
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
