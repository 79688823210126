import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import React from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";

export { PaginatedNav };

interface PaginatedNavProps {
  page: number;
  totalPages: number;
  onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
}

function PaginatedNav({ page, totalPages, onChange }: PaginatedNavProps) {
  const location = useLocation();
  return (
    <Pagination
      color="primary"
      size="large"
      showFirstButton
      onChange={onChange}
      showLastButton
      page={page}
      count={totalPages}
      renderItem={(item) => {
        const queryParameters = new URLSearchParams(location.search);
        queryParameters.set("page", `${item.page}`);
        return (
          <PaginationItem
            component={RouterLink}
            to={{
              pathname: location.pathname,
              search: queryParameters.toString(),
            }}
            {...item}
          />
        );
      }}
    />
  );
}
