import React, { useState } from "react";

export { useModal };

interface UseModaProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseModal: () => Promise<void>;
  handleConfirmModal: () => Promise<void>;
}

function useModal(
  onConfirm?: () => void | Promise<void>,
  onClose?: () => void | Promise<void>
): UseModaProps {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = async () => {
    if (onClose != undefined) {
      await Promise.resolve(onClose());
    }
    setShowModal(false);
  };

  const handleConfirmModal = async () => {
    if (onConfirm != undefined) {
      await Promise.resolve(onConfirm());
    }
    setShowModal(false);
  };

  return {
    showModal,
    setShowModal,
    handleCloseModal,
    handleConfirmModal,
  };
}
