import {
  Box,
  Button,
  Modal,
  Fade,
  Typography,
  Grid,
  ListItemText,
  ListItemButton,
  List,
  TextField,
} from "@mui/material";
import { Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";

import { PaginatedNav } from "./PaginatedNav";

import { PollAssignment, Pollster } from "common/apiTypes";
import { CustomTextField } from "common/formUtilities";
import { requestSubmitForm } from "common/utilities";

import { PollAssignmentRepository } from "repositories/PollAssignmentRepository";
import { PollsterRepository } from "repositories/PollsterRepository";
import { useGetPaginated } from "repositories/utils";

export { PollsterSearchModal, PollAssignmentModal };

const schema = yup.object().shape({
  name: yup.string().default(""),
  last_name: yup.string().default(""),
});

const assignmentSchema = yup.object().shape({
  female_response_limit: yup.number().min(0).default(0),
  male_response_limit: yup.number().min(0).default(0),
  place: yup.string().default("").required(),
});

interface FormValues {
  name: string;
  last_name: string;
}

interface AssignmentFormValues {
  female_response_limit: number;
  male_response_limit: number;
  place: string;
}

interface PollsterSearchFormProps {
  formikProps: FormikProps<any>;
}

function PollsterSearchForm({
  formikProps,
}: PollsterSearchFormProps): JSX.Element {
  return (
    <form
      id={"pollsterSearchForm"}
      onSubmit={formikProps.handleSubmit}
      css={{
        width: "100%",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <CustomTextField name="name" label="Nombre" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField name="last_name" label="Apellido" />
        </Grid>
      </Grid>
      <Button variant="contained" type="submit" css={{ marginTop: "1rem" }}>
        Consultar
      </Button>
    </form>
  );
}

const calculateTotal = (
  female_response_limit: string,
  male_response_limit: string
) => {
  const total = parseInt(female_response_limit) + parseInt(male_response_limit);
  if (isNaN(total)) {
    return "0";
  } else {
    return `${total}`;
  }
};

interface PollsterAssignmentForm {
  formikProps: FormikProps<any>;
}

function PollsterAssignmentForm({
  formikProps,
}: PollsterAssignmentForm): JSX.Element {
  return (
    <form
      id={"assignPollsterForm"}
      onSubmit={formikProps.handleSubmit}
      css={{
        width: "100%",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <CustomTextField
            name="male_response_limit"
            label="Respuestas (Hombres)"
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            name="female_response_limit"
            label="Respuestas (Mujeres)"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="total_response_limit"
            name="total_response_limit"
            label="Respuestas (Total)"
            value={calculateTotal(
              formikProps.values.female_response_limit,
              formikProps.values.male_response_limit
            )}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField name="place" label="Lugar" />
        </Grid>
      </Grid>
    </form>
  );
}

interface CreatePollsterAssignmentModalProps {
  handleClose?: () => void;
  handleConfirm?: () => void;
  show: boolean;
  selectedPollster: Pollster | undefined;
  setSelectedPollster: (pollster: Pollster | undefined) => void;
}

function PollsterSearchModal({
  handleClose,
  handleConfirm,
  show,
  selectedPollster,
  setSelectedPollster,
}: CreatePollsterAssignmentModalProps): JSX.Element {
  const [searchParams, setSearchParams] = useState(new URLSearchParams());

  const [pollsters, refreshPollsters] = useGetPaginated<Pollster>(
    PollsterRepository.getPollsters,
    searchParams
  );

  const updateSearch = async (values: FormValues) => {
    const newSearchParams = new URLSearchParams();
    for (const key of Object.keys(values)) {
      newSearchParams.set(key, `${(values as any)[key]}`);
    }
    setSearchParams(newSearchParams);
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        borderRadius: "1rem",
      }}
    >
      <Fade in={show}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItem: "flex-start",
            justifyContent: "flex-start",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "800px",
            bgcolor: "background.paper",
            borderRadius: "1rem",
            p: 4,
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            css={{
              marginBottom: "1.5rem",
            }}
          >
            Buscar Encuestador
          </Typography>
          <Formik
            validationSchema={schema}
            validateOnChange={false}
            onSubmit={updateSearch}
            initialValues={schema.getDefault()}
          >
            {(props) => <PollsterSearchForm formikProps={props} />}
          </Formik>
          {pollsters.results.length > 0 ? (
            <>
              <List
                component="div"
                aria-label="secondary mailbox folder"
                sx={{ marginBottom: "1rem" }}
              >
                {pollsters.results.map((pollster, _index) => {
                  return (
                    <ListItemButton
                      key={pollster.id}
                      selected={selectedPollster?.id === pollster.id}
                      onClick={() => {
                        setSelectedPollster(pollster);
                      }}
                    >
                      <ListItemText
                        primary={`${pollster.name} ${pollster.last_name}`}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
              <PaginatedNav
                page={pollsters.page}
                totalPages={pollsters.total_pages}
              />
            </>
          ) : null}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: "2rem",
              alignSelf: "flex-end",
            }}
          >
            <Button variant="contained" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              disabled={selectedPollster == undefined}
              onClick={handleConfirm}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

interface PollAssignmentModalProps {
  handleClose?: () => void;
  handleConfirm?: () => void;
  show: boolean;
  pollster?: Pollster;
  pollId?: string;
  pollAssignment?: PollAssignment;
}

function PollAssignmentModal({
  handleClose,
  handleConfirm,
  show,
  pollster,
  pollId,
  pollAssignment,
}: PollAssignmentModalProps): JSX.Element {
  const [initialValues, setInitialValues] = useState<AssignmentFormValues>(
    assignmentSchema.getDefault()
  );

  useEffect(() => {
    if (pollAssignment != null) {
      setInitialValues({
        female_response_limit: pollAssignment.female_response_limit,
        male_response_limit: pollAssignment.male_response_limit,
        place: pollAssignment.place,
      });
    }
  }, [pollAssignment]);

  const createPollsterAssignment = async (values, actions) => {
    if (pollster == undefined || pollId == undefined) {
      return;
    }
    const data = {
      pollster: pollster.id,
      poll: pollId,
      male_response_limit: values.male_response_limit,
      female_response_limit: values.female_response_limit,
      place: values.place,
    };
    actions.resetForm();
    const response = await PollAssignmentRepository.createPollAssignment(data);
    if (handleConfirm != undefined) {
      await handleConfirm();
    }
  };

  const updatePollsterAssignment = async (values, actions) => {
    if (pollAssignment == undefined) {
      return;
    }
    const data = {
      pollster: pollAssignment.pollster.id,
      poll: pollAssignment.poll.id,
      male_response_limit: values.male_response_limit,
      female_response_limit: values.female_response_limit,
      place: values.place,
    };
    actions.resetForm();
    const response = await PollAssignmentRepository.updatePollAssignment(
      data,
      pollAssignment.id
    );
    if (handleConfirm != undefined) {
      await handleConfirm();
    }
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        borderRadius: "1rem",
      }}
    >
      <Fade in={show}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItem: "flex-start",
            justifyContent: "flex-start",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "800px",
            bgcolor: "background.paper",
            borderRadius: "1rem",
            p: 4,
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Asignar Encuestador
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="p"
            css={{
              marginBottom: "1.5rem",
            }}
          >
            {pollster?.name} {pollster?.last_name}
          </Typography>
          <Formik
            validationSchema={assignmentSchema}
            validateOnChange={false}
            onSubmit={async (values, actions) => {
              if (pollAssignment != null) {
                await updatePollsterAssignment(values, actions);
              } else {
                await createPollsterAssignment(values, actions);
              }
            }}
            initialValues={initialValues}
            enableReinitialize={true}
          >
            {(props) => <PollsterAssignmentForm formikProps={props} />}
          </Formik>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: "2rem",
              alignSelf: "flex-end",
            }}
          >
            <Button variant="contained" disableRipple onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              disableRipple
              onClick={() => requestSubmitForm("assignPollsterForm")}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
