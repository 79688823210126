import { ResultsCardIndividual } from "../../components/ResultsCard/ResultsCardIndividual";
import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import { Box } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Base } from "common/Base";
import { CustomBreadCrumbs } from "common/BreadCrumbs";
import { ContentContainer } from "common/ContentContainer";
import { TabNames } from "common/NavBar";
import { NavBar } from "common/NavBar";
import { PageTitle } from "common/Titles";
import { Poll, PollRecord } from "common/apiTypes";
import { formatDate } from "common/utilities";

import { InformationCard } from "components/InformationCard";

import { PollRecordRepository } from "repositories/PollRecordRepository";
import { PollRepository } from "repositories/PollRepository";

export { PollRecordPage };

function PollRecordPage(): JSX.Element {
  const { id: pollId, recordId } = useParams();
  const [poll, setPoll] = useState<Poll | undefined>();
  const [pollRecord, setPollRecord] = useState<PollRecord | undefined>();

  const refreshPollRecord = async () => {
    if (recordId == undefined) {
      return;
    }
    const newPollRecord = await PollRecordRepository.getPollRecord(recordId);
    if (newPollRecord == null) {
      return;
    }
    setPollRecord(newPollRecord);
  };

  const refreshPoll = async () => {
    if (pollId == undefined) {
      return;
    }
    const newPoll = await PollRepository.getPoll(pollId);
    if (newPoll == null) {
      return;
    }
    console.log(`SURVEY: ${newPoll}`);
    setPoll(newPoll);
  };

  useEffect(() => {
    void refreshPoll();
    void refreshPollRecord();
  }, [pollId, recordId]);

  const selectedChoices = useMemo((): Map<number, Map<number, number[]>> => {
    if (pollRecord == undefined || pollRecord.answers == undefined) {
      return new Map();
    }
    const selectedChoicesMap = new Map<number, Map<number, number[]>>();
    for (const answerRecord of pollRecord.answers) {
      const questionNumber = answerRecord.choice.question_number;
      let selectedChoices = selectedChoicesMap.get(questionNumber);
      if (selectedChoices == undefined) {
        selectedChoices = new Map<number, number[]>();
        selectedChoicesMap.set(questionNumber, selectedChoices);
      }
      const headerNumber = answerRecord.choiceHeader?.number ?? 0;
      let choiceArray = selectedChoices.get(headerNumber);
      if (choiceArray == undefined) {
        choiceArray = [];
        selectedChoices.set(headerNumber, choiceArray);
      }
      choiceArray.push(answerRecord.choice.number);
    }
    console.log(selectedChoicesMap);
    return selectedChoicesMap;
  }, [pollRecord]);

  return (
    <Base>
      <NavBar currentTab={TabNames.Encuestas} />
      <ContentContainer>
        <CustomBreadCrumbs
          breadcrumbs={[{ label: "Encuestas", targetUrl: "/polls" }]}
        />
        <PageTitle title={"Consulta de Resultados de Encuesta"} />
        {pollRecord != undefined && poll != undefined && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InformationCard
              title="Información de la Encuesta"
              rows={[
                {
                  name: "Lugar",
                  content: pollRecord.poll_assignment.place,
                },
                {
                  name: "Fecha y Hora",
                  content: formatDate(new Date(pollRecord.answer_datetime)),
                },
                {
                  name: "Encuestador",
                  content: `${pollRecord.pollster.name} ${pollRecord.pollster.last_name}`,
                },
                // {
                //   name: "No. Respuesta",
                //   content: pollRecord.surveyRecordNumber,
                // },
              ]}
              image={pollRecord.images[0] ?? ""}
              audio={pollRecord.audios[0] ?? ""}
              latitude={pollRecord.latitude}
              longitude={pollRecord.longitude}
            />
            <InformationCard
              title="Información del Encuestado"
              rows={[
                {
                  name: "Sexo",
                  content: pollRecord.gender,
                },
                {
                  name: "Edad",
                  content: pollRecord.age,
                },
                {
                  name: "Escolaridad",
                  content: pollRecord.education_level,
                },
              ]}
              image=""
            />
            {pollRecord?.answers.length > 0 &&
              poll?.questions.length > 0 &&
              poll?.questions.map((question) => {
                if (question.kind == "SV" || question.kind == "MV") {
                  return (
                    <ResultsCardIndividual
                      key={question.id}
                      questionNumber={question.number}
                      questionTitle={question.text}
                      questionKind={question.kind}
                      choiceVector={question.choices}
                      selectedChoices={selectedChoices.get(question.number)}
                    />
                  );
                } else {
                  return (
                    <ResultsCardIndividual
                      key={question.id}
                      questionNumber={question.number}
                      questionTitle={question.text}
                      questionKind={question.kind}
                      choiceMatrix={{
                        choiceItems: question.choices,
                        choiceHeaders: question.choiceHeaders,
                      }}
                      selectedChoices={selectedChoices.get(question.number)}
                    />
                  );
                }
              })}
          </Box>
        )}
      </ContentContainer>
    </Base>
  );
}
