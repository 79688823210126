import { CustomTextField } from "../common/formUtilities";
import { KeyboardArrowDown, MoreHoriz } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { FormikProps } from "formik";
import React, { useState } from "react";

import { QuestionCardMatrix } from "./QuestionCardMatrix";
import { QuestionCardVector } from "./QuestionCardVector";

export { QuestionCard };

interface QuestionCardProps {
  questionIndex: number;
  formikProps: FormikProps<any>;
  removeQuestion: (index: number) => any;
  addQuestion: (obj: any) => void;
}

function QuestionCard({
  questionIndex,
  formikProps,
  removeQuestion,
  addQuestion,
}: QuestionCardProps): JSX.Element {
  const [cardCollapsed, setCardCollapsed] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>();
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: "2rem",
        width: "100%",
        borderRadius: "12px",
        padding: 0,
        border: "1px solid #fff",
        wordBreak: "normal",
        overflowWrap: "break-word",
        marginBottom: "2rem",
      }}
    >
      <CardHeader
        sx={{
          margin: 0,
          padding: "0.5rem 1rem",
          display: "flex",
          flexDirection: "row",
          flex: "1",
          backgroundColor: "#CA6121",
          "& .MuiCardHeader-title": {
            textAlign: "center",
          },
          "& .MuiCardHeader-action": {
            margin: 0,
          },
        }}
        avatar={
          <IconButton
            aria-label="collapse"
            onClick={() => {
              setCardCollapsed(!cardCollapsed);
            }}
          >
            <KeyboardArrowDown
              sx={{
                transform: cardCollapsed ? "rotate(0)" : "rotate(-90deg)",
                transition: "transform 1s",
              }}
            />
          </IconButton>
        }
        action={
          <>
            <IconButton
              aria-label="settings"
              sx={{
                margin: 0,
              }}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreHoriz />
            </IconButton>
            <Menu
              id="questionMenu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  removeQuestion(questionIndex);
                  handleClose();
                }}
              >
                Borrar
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const question = formikProps.values.questions[questionIndex];
                  if (question == null) {
                    return;
                  }
                  const questionCopy = JSON.parse(JSON.stringify(question));
                  questionCopy.number = formikProps.values.questions.length + 1;
                  addQuestion(questionCopy);
                  handleClose();
                }}
              >
                Copiar
              </MenuItem>
            </Menu>
          </>
        }
        title={`"Pregunta #${questionIndex + 1}"`}
        titleTypographyProps={{
          variant: "h5",
        }}
      />
      <Collapse in={!cardCollapsed} timeout={1000}>
        <CardContent
          sx={{
            padding: "2rem 4rem",
          }}
        >
          <CustomTextField
            fullWidth
            id={`questions[${questionIndex}].text`}
            name={`questions[${questionIndex}].text`}
            label="Texto de la Pregunta"
            sx={{ marginBottom: "2rem" }}
          />
          <CustomTextField
            fullWidth
            select
            id={`questions[${questionIndex}].kind`}
            name={`questions[${questionIndex}].kind`}
            label="Tipo de Pregunta"
            sx={{ marginBottom: "2rem" }}
          >
            <MenuItem value="SV">Respuesta Única (Lista)</MenuItem>
            <MenuItem value="MV">Respuesta Múltiple (Lista)</MenuItem>
            <MenuItem value="SM">Respuesta Única (Tabla)</MenuItem>
            <MenuItem value="MM">Respuesta Múltiple (Tabla)</MenuItem>
          </CustomTextField>
          {formikProps.values.questions[questionIndex].kind == "SV" ||
          formikProps.values.questions[questionIndex].kind == "MV" ? (
            <QuestionCardVector questionIndex={questionIndex} />
          ) : (
            <QuestionCardMatrix questionIndex={questionIndex} />
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
}
