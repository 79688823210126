import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React from "react";
import { render } from "react-dom";

import "common/yupMessages";

import { App } from "./App";

render(<App />, document.getElementById("app"));
