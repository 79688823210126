import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonIcon from "@mui/icons-material/Person";
import React from "react";

import { NavigationTab } from "common/NavigationTab";
import { centeredFlex } from "common/styles";

export { NavBar, TabNames };

enum TabNames {
  Encuestadores = "ENCUESTADORES",
  Encuestas = "ENCUESTAS",
}

interface NavBarProps {
  currentTab: TabNames;
}

function NavBar({ currentTab }: NavBarProps): JSX.Element {
  return (
    <div
      css={[
        centeredFlex,
        {
          backgroundColor: "#272627",
          width: "100%",
          justifyContent: "flex-start",
          paddingLeft: "1rem",
          paddingTop: "0.5rem",
          gap: "1rem",
        },
      ]}
    >
      <NavigationTab
        isActive={currentTab == TabNames.Encuestas}
        targetUrl={"/polls"}
      >
        <AssignmentIcon />
        <span>Encuestas</span>
      </NavigationTab>
      <NavigationTab
        isActive={currentTab == TabNames.Encuestadores}
        targetUrl={"/pollsters"}
      >
        <PersonIcon />
        <span>Encuestadores</span>
      </NavigationTab>
    </div>
  );
}
