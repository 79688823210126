import React from "react";

import { fileToBase64 } from "common/utilities";

export { ImageInput };

interface ImageInputProps {
  id: string;
  onPick: (b64: string) => void;
}

function ImageInput({ id, onPick }: ImageInputProps): JSX.Element {
  return (
    <input
      id={id}
      type="file"
      multiple={true}
      hidden
      onChange={async (event) => {
        const files = event.currentTarget.files;
        if (files == null) {
          return;
        }
        for (const file of files) {
          const b64 = (await fileToBase64(file)) as string;
          onPick(b64);
        }
      }}
    />
  );
}
