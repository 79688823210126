import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import { Typography } from "@mui/material";
import React from "react";

export { PageTitle };

interface PageTitleProps {
  title: string;
}

function PageTitle({ title }: PageTitleProps): JSX.Element {
  return (
    <div>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        sx={{ textAlign: "center", margin: 0 }}
      >
        {title}
      </Typography>
    </div>
  );
}
