import React from "react";

import { BasicApi, RESOURCE_URLS } from "common/api";
import {
  CreatePollAssignment,
  PageResponse,
  PollAssignment,
  Transaction,
} from "common/apiTypes";

export { PollAssignmentRepository };

class PollAssignmentRepository {
  static async getPollAssignment(id: number | string) {
    return await BasicApi.getById<PollAssignment>(
      RESOURCE_URLS.POLL_ASSIGNMENT,
      id
    );
  }

  static async getPollAssignments(searchParams?: URLSearchParams) {
    return await BasicApi.get<PageResponse<PollAssignment>>(
      RESOURCE_URLS.POLL_ASSIGNMENT,
      searchParams
    );
  }

  static async createPollAssignment(
    data: CreatePollAssignment
  ): Promise<PollAssignment | null> {
    const response = await BasicApi.post<CreatePollAssignment>(
      RESOURCE_URLS.POLL_ASSIGNMENT,
      data
    );
    if (response == null) {
      return null;
    }
    return response.data as PollAssignment;
  }

  static async updatePollAssignment(
    data: CreatePollAssignment,
    id: number | string
  ): Promise<PollAssignment | null> {
    const response = await BasicApi.patch<CreatePollAssignment>(
      RESOURCE_URLS.POLL_ASSIGNMENT,
      id,
      data
    );
    if (response == null) {
      return null;
    }
    return response.data as PollAssignment;
  }

  static async deletePollAssignment(id: number | string) {
    return await BasicApi.delete(RESOURCE_URLS.POLL_ASSIGNMENT, id);
  }

  static createPollAssignmentTransaction(
    data: CreatePollAssignment
  ): Transaction {
    return {
      url: `/${RESOURCE_URLS.POLL_ASSIGNMENT}/`,
      data: data,
      context_set: {},
      context_get: {},
      method: "POST",
    };
  }

  static updatePollAssignmentTransaction(
    data: CreatePollAssignment,
    id: number | string
  ): Transaction {
    return {
      url: `/${RESOURCE_URLS.POLL_ASSIGNMENT}/${id}/`,
      data: data,
      context_set: {},
      context_get: {},
      method: "PATCH",
    };
  }
}
