import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Link } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export { CustomBreadCrumbs };

interface BreadcrumbsLink {
  label: string;
  targetUrl: string;
}

interface CustomBreadCrumbsProps {
  breadcrumbs: BreadcrumbsLink[];
}

function CustomBreadCrumbs({
  breadcrumbs,
}: CustomBreadCrumbsProps): JSX.Element {
  return (
    <div
      css={{
        borderRadius: "15px",
        backgroundColor: "#575757",
        width: "100%",
        padding: "0.5rem 1.5rem",
      }}
    >
      <Breadcrumbs
        separator={
          <NavigateNextIcon fontSize="small" css={{ cursor: "pointer" }} />
        }
        aria-label="breadcrumb"
      >
        {breadcrumbs.map((breadcrumb, _index) => {
          return (
            <Link
              key={breadcrumb.targetUrl}
              component={RouterLink}
              underline="hover"
              color="inherit"
              to={breadcrumb.targetUrl}
            >
              {breadcrumb.label}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
