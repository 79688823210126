import { Question } from "../common/apiTypes";
import { useModal } from "../common/hooks";
import { AddPhotoAlternate } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, IconButton, List, ListItem } from "@mui/material";
import { FieldArray } from "formik";
import React, { useRef } from "react";

import { RangeModal } from "./RangeModal";

import { CustomTextField } from "common/formUtilities";

import { ImageArray } from "components/ImageArray";
import { ImageInput } from "components/ImageInput";

interface QuestionCardVectorProps {
  questionIndex: number;
}

const addChoice = (choiceNumber: number) => {
  return {
    text: "",
    kind: "normal",
    number: choiceNumber,
    images: [],
  };
};

function QuestionCardVector({ questionIndex }: QuestionCardVectorProps) {
  const formikRef = useRef();
  const rangeModal = useModal();
  return (
    <>
      <FieldArray name={`questions[${questionIndex}].choices`}>
        {(fieldArrayPropsChoices) => {
          const { push, remove, form } = fieldArrayPropsChoices;
          const { values } = form;
          const { questions } = values;
          const question: Question = questions?.[questionIndex] ?? [];

          return (
            <>
              <RangeModal
                valueRef={formikRef}
                show={rangeModal.showModal}
                handleConfirm={async () => {
                  if (formikRef.current != null) {
                    const minimum = formikRef.current.values.minimum;
                    const maximum = formikRef.current.values.maximum;
                    question?.choices?.forEach((choice, index) => {
                      remove(index);
                    });
                    let c = 1;
                    for (let i = minimum; i <= maximum; i++) {
                      const choice = addChoice(c);
                      choice.text = `${i}`;
                      push(choice);
                      c += 1;
                    }
                    console.log(values);
                  }
                  await rangeModal.handleConfirmModal();
                }}
                handleClose={rangeModal.handleCloseModal}
              />
              {question?.choices?.length === 1 && (
                <Button
                  variant="contained"
                  type="button"
                  css={{
                    marginBottom: "1rem",
                  }}
                  onClick={() => {
                    rangeModal.setShowModal(true);
                  }}
                >
                  Crear Rango
                </Button>
              )}

              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                {question?.choices?.map((choice, index: number) => (
                  <Box
                    key={index}
                    sx={{
                      marginBottom: "2rem",
                    }}
                  >
                    <ListItem
                      key={index}
                      disableGutters
                      alignItems="flex-start"
                      sx={{
                        marginBottom: "2rem",
                        padding: 0,
                      }}
                    >
                      <CustomTextField
                        fullWidth
                        id={`questions[${questionIndex}].choices[${index}].text`}
                        name={`questions[${questionIndex}].choices[${index}].text`}
                        label={`Opción ${index + 1}`}
                      />
                      <ImageInput
                        id={`questions[${questionIndex}].choices[${index}].imageButton`}
                        onPick={(b64: string) => {
                          choice.images.push(b64);
                          form.setFieldValue(
                            `questions[${questionIndex}].choices[${index}].images`,
                            choice.images
                          );
                        }}
                      />
                      <IconButton
                        sx={{
                          marginLeft: "1rem",
                        }}
                        onClick={async () => {
                          const inputImages = document.getElementById(
                            `questions[${questionIndex}].choices[${index}].imageButton`
                          );
                          if (inputImages != null) {
                            inputImages.click();
                          }
                        }}
                      >
                        <AddPhotoAlternate />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </ListItem>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                        flexGrow: "0",
                        overflowX: "auto",
                      }}
                    >
                      <ImageArray
                        name={`questions[${questionIndex}].choices[${index}].images`}
                        choice={choice}
                      />
                    </Box>
                  </Box>
                ))}
              </List>
              <Button
                variant="contained"
                onClick={() => {
                  push(addChoice(question?.choices.length + 1));
                }}
              >
                AÑADIR
              </Button>
            </>
          );
        }}
      </FieldArray>
    </>
  );
}

export { QuestionCardVector };
