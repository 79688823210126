import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Formik, FormikProps } from "formik";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import { Base } from "common/Base";
import { CustomBreadCrumbs } from "common/BreadCrumbs";
import { ContentContainer } from "common/ContentContainer";
import { TabNames } from "common/NavBar";
import { NavBar } from "common/NavBar";
import { PageTitle } from "common/Titles";
import { Poll } from "common/apiTypes";
import { CustomTextField } from "common/formUtilities";

import { CreateButton } from "components/CreateButton";
import { PaginatedNav } from "components/PaginatedNav";

import { PollRepository } from "repositories/PollRepository";
import { useGetPaginated } from "repositories/utils";

export { PollSearch };

const schema = yup.object().shape({
  title: yup.string().default(""),
  creation_date_min: yup.string().default(""),
  creation_date_max: yup.string().default(""),
});

interface FormValues {
  title: string;
  creation_date_min: string;
  creation_date_max: string;
}

interface PollsterSearchFormProps {
  formikProps: FormikProps<any>;
}

function PollsterSearchForm({
  formikProps,
}: PollsterSearchFormProps): JSX.Element {
  return (
    <form
      onSubmit={formikProps.handleSubmit}
      css={{
        width: "100%",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <CustomTextField name="title" label="Titulo" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="date"
            name="creation_date_min"
            label="Fecha Mínima"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="date"
            name="creation_date_max"
            label="Fecha Máxima"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Button variant="contained" type="submit" css={{ marginTop: "1rem" }}>
        Consultar
      </Button>
    </form>
  );
}

interface PollTableProps {
  polls: Poll[];
}

function PollsTable({ polls }: PollTableProps): JSX.Element {
  const navigate = useNavigate();
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          marginBottom: "2rem",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#DD6B25" }}>
            <TableRow>
              <TableCell align="center">Ver</TableCell>
              <TableCell align="center">Titulo</TableCell>
              <TableCell align="center">Lugar</TableCell>
              <TableCell align="center">Fecha</TableCell>
              <TableCell align="center">
                Encuestadores
                <br />/ Respuestas
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {polls.map((poll) => (
              <TableRow
                key={poll.id}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell align="center" sx={{ color: "#DD6B25" }}>
                  <IconButton
                    onClick={() => {
                      navigate(`/poll/${poll.id}`);
                    }}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">{poll.title}</TableCell>
                <TableCell align="center">{poll.place}</TableCell>
                <TableCell align="center">{poll.creation_date}</TableCell>
                <TableCell align="center">
                  {poll.pollsters_amount} / {poll.answers_amount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function PollSearch(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const [polls, refreshPolls] = useGetPaginated<Poll>(
    PollRepository.getPolls,
    searchParams
  );

  const updateSearch = async (values: FormValues) => {
    const newSearchParams = new URLSearchParams(searchParams);
    for (const key of Object.keys(values)) {
      newSearchParams.set(key, `${(values as any)[key]}`);
    }
    setSearchParams(newSearchParams);
  };

  return (
    <Base>
      <NavBar currentTab={TabNames.Encuestas} />
      <ContentContainer>
        <CustomBreadCrumbs
          breadcrumbs={[{ label: "Encuestas", targetUrl: "/polls" }]}
        />
        <PageTitle title={"Consulta de Encuestas"} />
        <CreateButton targetUrl={"/poll/-1"}>Crear Encuesta</CreateButton>
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          onSubmit={updateSearch}
          initialValues={schema.getDefault()}
        >
          {(props) => <PollsterSearchForm formikProps={props} />}
        </Formik>
        <PollsTable polls={polls.results} />
        <PaginatedNav page={polls.page} totalPages={polls.total_pages} />
      </ContentContainer>
    </Base>
  );
}
