import React from "react";

import { BasicApi, RESOURCE_URLS } from "common/api";
import { CreatePoll, PageResponse, Poll, Transaction } from "common/apiTypes";

export { PollRepository };

class PollRepository {
  static async getPoll(id: number | string) {
    return await BasicApi.getById<Poll>(RESOURCE_URLS.POLL, id);
  }

  static async getPolls(searchParams?: URLSearchParams) {
    return await BasicApi.get<PageResponse<Poll>>(
      RESOURCE_URLS.POLL,
      searchParams
    );
  }

  static async createPoll(data: CreatePoll): Promise<Poll | null> {
    const response = await BasicApi.post<CreatePoll>(RESOURCE_URLS.POLL, data);
    if (response == null) {
      return null;
    }
    return response.data as Poll;
  }

  static async deletePoll(id: number | string) {
    return await BasicApi.delete(RESOURCE_URLS.POLL, id);
  }

  static createPollTransaction(data: CreatePoll): Transaction {
    return {
      url: `/${RESOURCE_URLS.POLL}/`,
      data: data,
      context_set: {
        pollId: "id",
      },
      context_get: {},
      method: "POST",
    };
  }

  static updatePollTransaction(
    data: CreatePoll,
    id: number | string
  ): Transaction {
    return {
      url: `/${RESOURCE_URLS.POLL}/${id}/`,
      data: data,
      context_set: {},
      context_get: {},
      method: "PATCH",
    };
  }

  static resetPollTransaction(id: number | string): Transaction {
    return {
      url: `/${RESOURCE_URLS.POLL}/${id}/reset/`,
      data: {},
      context_set: {},
      context_get: {},
      method: "POST",
    };
  }
}
