import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import React from "react";

export { Base };

interface BaseProps {
  children: React.ReactNode;
}

function Base({ children }: BaseProps): JSX.Element {
  return (
    <div
      css={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#363435",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
}
