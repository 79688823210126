import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import { Button, Alert } from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { BasicApi, RESOURCE_URLS } from "common/api";
import { LoginData } from "common/apiTypes";
import { CustomTextField } from "common/formUtilities";
import { centeredFlex } from "common/styles";

export { Login };

const schema = yup.object().shape({
  username: yup.string().required().default(""),
  password: yup.string().required().default(""),
});

interface LoginContainerProps {
  children: React.ReactNode;
}

function LoginContainer({ children }: LoginContainerProps) {
  return (
    <div
      css={[
        centeredFlex,
        {
          backgroundColor: "#e5e5e5",
          width: "100%",
          minHeight: "100vh",
        },
      ]}
    >
      <div
        css={[
          centeredFlex,
          {
            backgroundColor: "#454545",
            width: "70vw",
            height: "70vh",
            borderRadius: "1.25rem",
            boxShadow: "10px 10px 12px #0005",
            gap: "2rem",
            justifyContent: "space-around",
            padding: "2rem 4rem",
            "& > *": {
              flex: 1,
            },
          },
        ]}
      >
        {children}
      </div>
    </div>
  );
}

function Login(): JSX.Element {
  const navigate = useNavigate();
  const [invalidLogin, setInvalidLogin] = useState(false);
  return (
    <LoginContainer>
      <div>
        <img
          src="/static/caballodehierro/images/logo.png"
          alt="Logo.png"
          css={{
            maxWidth: "100%",
            objectFit: "contain",
          }}
        />
      </div>
      <Formik
        validationSchema={schema}
        validateOnChange={false}
        onSubmit={async (values) => {
          const response = await BasicApi.post<LoginData>(
            RESOURCE_URLS.LOGIN,
            values
          );
          if (response?.status === 200) {
            navigate("/polls");
          } else {
            setInvalidLogin(true);
          }
        }}
        initialValues={schema.getDefault()}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            css={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              padding: "1rem",
              "& > *": {
                flex: 1,
              },
            }}
          >
            <CustomTextField
              id="username"
              name="username"
              type="text"
              label="Nombre de Usuario"
              variant="outlined"
            />
            <CustomTextField
              id="password"
              name="password"
              type="password"
              label="Contraseña"
              variant="outlined"
            />
            {invalidLogin && (
              <Alert severity="error">Usuario o contraseña inválidos</Alert>
            )}
            <Button variant="contained" type="submit">
              INGRESAR
            </Button>
          </form>
        )}
      </Formik>
    </LoginContainer>
  );
}
