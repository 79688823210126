import { formatDate } from "../../common/utilities";
import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Formik, FormikProps } from "formik";
import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import { Base } from "common/Base";
import { CustomBreadCrumbs } from "common/BreadCrumbs";
import { ContentContainer } from "common/ContentContainer";
import { TabNames } from "common/NavBar";
import { NavBar } from "common/NavBar";
import { PageTitle } from "common/Titles";
import { Poll, PollRecord } from "common/apiTypes";
import { CustomTextField } from "common/formUtilities";

import { PaginatedNav } from "components/PaginatedNav";

import { PollRecordRepository } from "repositories/PollRecordRepository";
import { useGetPaginated } from "repositories/utils";

export { PollRecordSearch };

const schema = yup.object().shape({
  pollster: yup.string().default(""),
  creation_date_min: yup.string().default(""),
  creation_date_max: yup.string().default(""),
});

interface FormValues {
  pollster: string;
  creation_date_min: string;
  creation_date_max: string;
}

interface PollsterSearchFormProps {
  formikProps: FormikProps<any>;
}

function PollsterRecordSearchForm({
  formikProps,
}: PollsterSearchFormProps): JSX.Element {
  return (
    <form
      onSubmit={formikProps.handleSubmit}
      css={{
        width: "100%",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <CustomTextField name="pollster" label="Encuestador" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="date"
            name="creation_date_min"
            label="Fecha Mínima"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="date"
            name="creation_date_max"
            label="Fecha Máxima"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Button variant="contained" type="submit" css={{ marginTop: "1rem" }}>
        Consultar
      </Button>
    </form>
  );
}

interface PollRecordsTable {
  pollRecords: PollRecord[];
}

function PollRecordsTable({ pollRecords }: PollRecordsTable): JSX.Element {
  const navigate = useNavigate();
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          marginBottom: "2rem",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#DD6B25" }}>
            <TableRow>
              <TableCell align="center">Ver</TableCell>
              <TableCell align="center">Encuestador</TableCell>
              <TableCell align="center">Lugar</TableCell>
              <TableCell align="center">Fecha</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pollRecords.map((pollRecord) => (
              <TableRow
                key={pollRecord.id}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell align="center" sx={{ color: "#DD6B25" }}>
                  <IconButton
                    onClick={() => {
                      navigate(
                        `/poll/${pollRecord.poll_assignment.poll}/answers/${pollRecord.id}`
                      );
                    }}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  {pollRecord.pollster.name} {pollRecord.pollster.last_name}
                </TableCell>
                <TableCell align="center">{pollRecord.place}</TableCell>
                <TableCell align="center">
                  {formatDate(new Date(pollRecord.answer_datetime))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function PollRecordSearch(): JSX.Element {
  const { id: pollId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pollRecords, refreshPollRecords] = useGetPaginated<PollRecord>(
    PollRecordRepository.getPollRecords,
    searchParams
  );

  const updateSearch = async (values: FormValues) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("poll", pollId ?? "-1");
    for (const key of Object.keys(values)) {
      newSearchParams.set(key, `${(values as any)[key]}`);
    }
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    void updateSearch(schema.getDefault());
  }, [pollId]);

  return (
    <Base>
      <NavBar currentTab={TabNames.Encuestas} />
      <ContentContainer>
        <CustomBreadCrumbs
          breadcrumbs={[{ label: "Encuestas", targetUrl: "/polls" }]}
        />
        <PageTitle title={"Consulta de Resultados de Encuesta"} />
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          onSubmit={updateSearch}
          initialValues={schema.getDefault()}
        >
          {(props) => <PollsterRecordSearchForm formikProps={props} />}
        </Formik>
        <PollRecordsTable pollRecords={pollRecords.results} />
        <PaginatedNav
          page={pollRecords.page}
          totalPages={pollRecords.total_pages}
        />
      </ContentContainer>
    </Base>
  );
}
