import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";

export { InformationCard };

interface InformationCardProps {
  title: string;
  rows: any[];
  image: string;
  audio?: string;
  latitude?: string;
  longitude?: string;
}

function InformationCard({
  title,
  rows,
  image,
  latitude = "",
  longitude = "",
  audio = "",
}: InformationCardProps): JSX.Element {
  console.log(`IMAGE: ${image}`);
  console.log(`AUDIO: ${audio}`);
  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: "2rem",
        width: "100%",
        borderRadius: "12px",
        padding: 0,
        border: "1px solid #fff",
        wordBreak: "normal",
        overflowWrap: "break-word",
        marginBottom: "2rem",
      }}
    >
      <CardHeader
        sx={{
          margin: 0,
          padding: "0.5rem 1rem",
          display: "flex",
          flexDirection: "row",
          flex: "1",
          backgroundColor: "#CA6121",
          "& .MuiCardHeader-title": {
            textAlign: "center",
          },
        }}
        title={title}
        titleTypographyProps={{
          variant: "h5",
        }}
      />
      <CardContent
        sx={{
          padding: "2rem 4rem",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {rows.map((row, index) => {
            return (
              <Box
                key={row.name}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  flexGrow: "1",
                  fontSize: "1.5rem",
                }}
              >
                <Typography
                  variant="body1"
                  component="span"
                  color="text.primary"
                  sx={{ flexBasis: "0.3", minWidth: "200px" }}
                >
                  {row.name}
                </Typography>
                <Typography
                  variant="body2"
                  component="span"
                  color="text.secondary"
                  sx={{ flexBasis: "0.7" }}
                >
                  {row.content}
                </Typography>
              </Box>
            );
          })}
          <Box
            component="img"
            src={image}
            sx={{
              width: "20vw",
              alignSelf: "flex-start",
            }}
          />
          {audio !== "" && (
            <Box
              sx={{
                // width: "20vw",
                marginTop: "1rem",
                alignSelf: "flex-start",
              }}
            >
              <audio controls>
                <source src={audio} type="audio/x-m4a" />
              </audio>
            </Box>
          )}
          {latitude !== "" && longitude !== "" && (
            <Box
              sx={{
                width: "100%",
                marginTop: "1rem",
                alignSelf: "center",
              }}
            >
              <iframe
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBhqcc0k2QMx1f0A6WvtoKUtT3yidJ4IdA&q=${latitude},${longitude}`}
                width="100%"
                height="500px"
                style={{ border: "0" }}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
