import React from "react";

import { BasicApi, RESOURCE_URLS } from "common/api";
import {
  CreateQuestion,
  PageResponse,
  Question,
  Transaction,
} from "common/apiTypes";

export { QuestionRepository };

class QuestionRepository {
  static async getQuestion(id: number | string) {
    return await BasicApi.getById<Question>(RESOURCE_URLS.QUESTION, id);
  }

  static async getQuestions(searchParams?: URLSearchParams) {
    return await BasicApi.get<PageResponse<Question>>(
      RESOURCE_URLS.QUESTION,
      searchParams
    );
  }

  static async createQuestion(data: CreateQuestion): Promise<Question | null> {
    const response = await BasicApi.post<CreateQuestion>(
      RESOURCE_URLS.QUESTION,
      data
    );
    if (response == null) {
      return null;
    }
    return response.data as Question;
  }

  static createQuestionTransaction(data: CreateQuestion): Transaction {
    return {
      url: `/${RESOURCE_URLS.QUESTION}/`,
      data: data,
      context_set: {
        questionId: "id",
      },
      context_get: {
        pollId: "poll",
      },
      method: "POST",
    };
  }

  static updateQuestionTransaction(data: CreateQuestion): Transaction {
    return {
      url: `/${RESOURCE_URLS.QUESTION}/`,
      data: data,
      context_set: {},
      context_get: {},
      method: "PATCH",
    };
  }
}
