import React, { useCallback, useEffect, useState } from "react";

import { PageResponse } from "common/apiTypes";

export { useGetById, useGetPaginated };

const defaultResponse = {
  next: undefined,
  previous: undefined,
  page: 1,
  count: 0,
  total_pages: 1,
  results: [],
};

function useGetById<T>(
  getFunction: (id: number | string) => Promise<T | null>,
  id: number | string
): [T | undefined, () => Promise<void>] {
  const [instance, setInstance] = useState<T | undefined>();

  const refresh = useCallback(async () => {
    if (id === -1 || id === "-1") {
      setInstance(undefined);
      return;
    }
    const newInstance = await getFunction(id);
    if (newInstance != null) {
      setInstance(newInstance);
    }
  }, [getFunction, id]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [instance, refresh];
}

function useGetPaginated<T>(
  getFunction: (
    searchParams?: URLSearchParams
  ) => Promise<PageResponse<T> | null>,
  searchParams?: URLSearchParams
): [PageResponse<T>, () => Promise<void>] {
  const [instance, setInstance] = useState<PageResponse<T>>(defaultResponse);

  const refresh = useCallback(async () => {
    const newInstance = await getFunction(searchParams);
    if (newInstance != null) {
      setInstance(newInstance);
    }
  }, [searchParams]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [instance, refresh];
}
