import { Question } from "../common/apiTypes";
import { AddPhotoAlternate } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  IconButton,
  ListItem,
  Typography,
  List,
} from "@mui/material";
import { FieldArray } from "formik";
import React from "react";

import { CustomTextField } from "common/formUtilities";

import { ImageArray } from "components/ImageArray";
import { ImageInput } from "components/ImageInput";
import { QuestionCardVector } from "components/QuestionCardVector";

const addChoice = (choiceNumber: number) => {
  return {
    text: "",
    kind: "header",
    number: choiceNumber,
    images: [],
  };
};

interface MatrixContentProps {
  questionIndex: number;
}

function QuestionCardMatrix({
  questionIndex,
}: MatrixContentProps): JSX.Element {
  return (
    <React.Fragment key={questionIndex}>
      <Typography
        variant="h4"
        component="div"
        sx={{
          marginBottom: "1rem",
        }}
      >
        Filas (Opciones)
      </Typography>
      <QuestionCardVector questionIndex={questionIndex} />
      <Typography
        variant="h4"
        component="div"
        sx={{
          marginTop: "2rem",
          marginBottom: "1rem",
        }}
      >
        Columnas (Encabezados)
      </Typography>
      <FieldArray name={`questions[${questionIndex}].choiceHeaders`}>
        {(fieldArrayPropsChoices) => {
          const { push, remove, form } = fieldArrayPropsChoices;
          const { values } = form;
          const { questions } = values;
          const question: Question = questions?.[questionIndex] ?? [];

          return (
            <React.Fragment key={questionIndex}>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                {question?.choiceHeaders?.map((choice, index: number) => (
                  <Box
                    key={index}
                    sx={{
                      marginBottom: "2rem",
                    }}
                  >
                    <ListItem
                      key={index}
                      disableGutters
                      alignItems="flex-start"
                      sx={{
                        marginBottom: "2rem",
                        padding: 0,
                      }}
                    >
                      <CustomTextField
                        fullWidth
                        id={`questions[${questionIndex}].choiceHeaders[${index}].text`}
                        name={`questions[${questionIndex}].choiceHeaders[${index}].text`}
                        label={`Encabezado ${index + 1}`}
                      />
                      <ImageInput
                        id={`questions[${questionIndex}].choiceHeaders[${index}].imageButton`}
                        onPick={(b64: string) => {
                          choice.images.push(b64);
                          form.setFieldValue(
                            `questions[${questionIndex}].choiceHeaders[${index}].images`,
                            choice.images
                          );
                        }}
                      />
                      <IconButton
                        sx={{
                          marginLeft: "1rem",
                        }}
                        onClick={async () => {
                          const inputImages = document.getElementById(
                            `questions[${questionIndex}].choiceHeaders[${index}].imageButton`
                          );
                          if (inputImages != null) {
                            inputImages.click();
                          }
                        }}
                      >
                        <AddPhotoAlternate />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </ListItem>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                        flexGrow: "0",
                        overflowX: "auto",
                      }}
                    >
                      <ImageArray
                        name={`questions[${questionIndex}].choices[${index}].images`}
                        choice={choice}
                      />
                    </Box>
                  </Box>
                ))}
              </List>
              <Button
                variant="contained"
                disableRipple
                onClick={() => {
                  push(addChoice(question?.choiceHeaders?.length + 1));
                }}
              >
                AÑADIR
              </Button>
            </React.Fragment>
          );
        }}
      </FieldArray>
    </React.Fragment>
  );
}

export { QuestionCardMatrix };
