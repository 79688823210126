import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import { Button, ButtonProps } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export { CreateButton };

interface CreateButtonProps extends ButtonProps {
  targetUrl: string;
}

function CreateButton(props: CreateButtonProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <Button
      variant="contained"
      type="button"
      css={{
        alignSelf: "flex-start",
      }}
      onClick={() => navigate(props.targetUrl)}
    >
      {props.children}
    </Button>
  );
}
