import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Fab, Grid } from "@mui/material";
import { FieldArray, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import { Base } from "common/Base";
import { CustomBreadCrumbs } from "common/BreadCrumbs";
import { ContentContainer } from "common/ContentContainer";
import { NavBar, TabNames } from "common/NavBar";
import { PageTitle } from "common/Titles";
import { BasicApi, RESOURCE_URLS } from "common/api";
import { CreatePoll, Choice, Poll, Question } from "common/apiTypes";
import { CustomTextField } from "common/formUtilities";

import { CreateButton } from "components/CreateButton";
import { QuestionCard } from "components/QuestionCard";

import { ChoiceRepository } from "repositories/ChoiceRepository";
import { PollRepository } from "repositories/PollRepository";
import { QuestionRepository } from "repositories/QuestionRepository";
import { useGetById } from "repositories/utils";

export { PollPage };

const choiceSchema = yup.object().shape({
  text: yup.string().default(""),
  images: yup.array().default([]).of(yup.string()),
});

const questionSchema = yup.object().shape({
  text: yup.string().default("").required(),
  choices: yup.array().default([]).of(choiceSchema),
  choiceHeaders: yup.array().default([]).of(choiceSchema),
});

const schema = yup.object().shape({
  title: yup.string().default("").required(),
  questions: yup.array().default([]).of(questionSchema),
});

const defaultValues = {
  title: "",
  questions: [
    {
      number: 1,
      text: "",
      kind: "SV",
      choices: [
        {
          text: "",
          kind: "normal",
          number: 1,
          images: [],
        },
      ],
      choiceHeaders: [
        {
          text: "",
          kind: "header",
          number: 1,
          images: [],
        },
      ],
    },
  ],
};

interface FormValues {
  title: string;
  questions: Question[];
}

interface PollFormProps {
  formikProps: FormikProps<any>;
  mode: "create" | "update";
}

function PollForm({ formikProps, mode }: PollFormProps): JSX.Element {
  const addQuestion = (questionNumber: number) => {
    return {
      number: questionNumber,
      text: "",
      kind: "SV",
      choices: [
        {
          text: "",
          kind: "normal",
          number: 1,
          images: [],
        },
      ],
      choiceHeaders: [
        {
          text: "",
          kind: "header",
          number: 1,
          images: [],
        },
      ],
    };
  };

  return (
    <form
      onSubmit={formikProps.handleSubmit}
      css={{
        width: "100%",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CustomTextField name="title" label="Título" />
        </Grid>
      </Grid>
      <FieldArray name="questions">
        {(fieldArrayProps) => {
          const { push, remove, form } = fieldArrayProps;
          const { values } = formikProps;
          const questions: Question[] = values.questions;

          return (
            <>
              {questions.map((question, index) => (
                <QuestionCard
                  key={`${index}`}
                  questionIndex={index}
                  formikProps={formikProps}
                  removeQuestion={remove}
                  addQuestion={push}
                />
              ))}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  marginTop: "2rem",
                  textAlign: "center",
                }}
                onClick={() => push(addQuestion(questions.length + 1))}
              >
                <Fab color="primary" variant="extended" aria-label="add">
                  <AddIcon
                    sx={{
                      marginRight: "0.5rem",
                      verticalAlign: "middle",
                    }}
                  />
                  AÑADIR PREGUNTA
                </Fab>
              </Box>
            </>
          );
        }}
      </FieldArray>
      <Button variant="contained" type="submit" css={{ marginTop: "1rem" }}>
        {mode === "create" ? "Crear" : "Modificar"}
      </Button>
    </form>
  );
}

function PollPage(): JSX.Element {
  const { id: pollId } = useParams();
  const [poll, refreshPoll] = useGetById<Poll>(
    PollRepository.getPoll,
    pollId ?? -1
  );
  const [initialValues, setInitialValues] = useState<any>(defaultValues);
  const navigate = useNavigate();

  useEffect(() => {
    if (poll != undefined) {
      setInitialValues({
        title: poll.title,
        questions: poll.questions,
      });
    }
  }, [poll]);

  const createPoll = async (values: FormValues) => {
    const pollCreateData: CreatePoll = {
      status: "active",
      title: values.title,
      place: "",
    };
    const transactionData = [
      PollRepository.createPollTransaction(pollCreateData),
    ];
    for (const question of values.questions) {
      const question_transaction = QuestionRepository.createQuestionTransaction(
        {
          number: question.number,
          kind: question.kind,
          text: question.text,
        }
      );
      transactionData.push(question_transaction);
      for (const choice of question.choices as Choice[]) {
        const choice_transaction = ChoiceRepository.createChoiceTransaction({
          number: choice.number,
          kind: choice.kind,
          text: choice.text,
          images: choice.images,
        });
        transactionData.push(choice_transaction);
      }
      if (question.kind === "SM" || question.kind === "MM") {
        for (const choice of question.choiceHeaders as Choice[]) {
          const choice_transaction = ChoiceRepository.createChoiceTransaction({
            number: choice.number,
            kind: choice.kind,
            text: choice.text,
            images: choice.images,
          });
          transactionData.push(choice_transaction);
        }
      }
    }
    const response = await BasicApi.post(
      RESOURCE_URLS.TRANSACTION,
      transactionData
    );
    if (response != null && response.status === 200) {
      const newId = response.data.pollId;
      if (newId != undefined) {
        navigate(`/poll/${newId}`);
      }
    }
  };

  const updatePoll = async (values: FormValues) => {
    if (poll == null) {
      return;
    }
    const pollUpdateData: CreatePoll = {
      status: "active",
      title: values.title,
      place: "",
    };
    const transactionData = [
      PollRepository.updatePollTransaction(pollUpdateData, poll.id),
      PollRepository.resetPollTransaction(poll.id),
    ];
    for (const question of values.questions) {
      const question_transaction = QuestionRepository.createQuestionTransaction(
        {
          number: question.number,
          kind: question.kind,
          text: question.text,
        }
      );
      question_transaction.data.poll = poll.id;
      question_transaction.context_get = {};
      transactionData.push(question_transaction);
      for (const choice of question.choices as Choice[]) {
        const choice_transaction = ChoiceRepository.createChoiceTransaction({
          number: choice.number,
          kind: choice.kind,
          text: choice.text,
          images: choice.images,
        });
        transactionData.push(choice_transaction);
      }
      if (question.kind === "SM" || question.kind === "MM") {
        for (const choice of question.choiceHeaders as Choice[]) {
          const choice_transaction = ChoiceRepository.createChoiceTransaction({
            number: choice.number,
            kind: choice.kind,
            text: choice.text,
            images: choice.images,
          });
          transactionData.push(choice_transaction);
        }
      }
    }
    const response = await BasicApi.post(
      RESOURCE_URLS.TRANSACTION,
      transactionData
    );
    console.log(response);
    if (response != null && response.status === 200) {
      const newId = response.data.pollId;
      if (newId != undefined) {
        navigate(`/polls/${newId}`);
      }
    }
  };

  const deletePoll = async () => {
    if (poll == null) {
      return;
    }
    const response = await PollRepository.deletePoll(poll.id);
    if (response.status == 200) {
      navigate("/poll", { replace: true });
    }
  };

  return (
    <Base>
      <NavBar currentTab={TabNames.Encuestas} />
      <ContentContainer>
        <CustomBreadCrumbs
          breadcrumbs={[{ label: "Encuestas", targetUrl: "/polls" }]}
        />
        <PageTitle title={poll == null ? "Crear Encuesta" : "Encuesta"} />
        {poll != null && (
          <CreateButton targetUrl={`/poll/${poll.id}/assignments`}>
            Encuestadores Asignados
          </CreateButton>
        )}
        {poll != null && (
          <CreateButton targetUrl={`/poll/${poll.id}/answers`}>
            Resultados Individuales
          </CreateButton>
        )}
        {poll != null && (
          <CreateButton targetUrl={`/poll/${poll.id}/results`}>
            Resultados Generales
          </CreateButton>
        )}
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          onSubmit={async (values) => {
            console.log(values);
            if (poll == null) {
              await createPoll(values);
            } else {
              await updatePoll(values);
            }
          }}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {(props) => (
            <>
              <PollForm
                formikProps={props}
                mode={poll == null ? "create" : "update"}
              />
            </>
          )}
        </Formik>
        {poll != null && (
          <Button
            variant="contained"
            color="error"
            type="submit"
            onClick={async () => {
              await deletePoll();
            }}
            css={{ alignSelf: "flex-start" }}
          >
            Eliminar
          </Button>
        )}
      </ContentContainer>
    </Base>
  );
}
