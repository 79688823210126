import React from "react";

import { BasicApi, RESOURCE_URLS } from "common/api";
import {
  CreatePollster,
  PageResponse,
  Pollster,
  Transaction,
} from "common/apiTypes";

export { PollsterRepository };

class PollsterRepository {
  static async getPollster(id: number | string) {
    return await BasicApi.getById<Pollster>(RESOURCE_URLS.POLLSTER, id);
  }

  static async getPollsters(searchParams?: URLSearchParams) {
    return await BasicApi.get<PageResponse<Pollster>>(
      RESOURCE_URLS.POLLSTER,
      searchParams
    );
  }

  static async createPollster(data: CreatePollster): Promise<Pollster | null> {
    const response = await BasicApi.post<CreatePollster>(
      RESOURCE_URLS.POLLSTER,
      data
    );
    if (response == null) {
      return null;
    }
    return response.data as Pollster;
  }

  static createPollsterTransaction(data: CreatePollster): Transaction {
    return {
      url: `/${RESOURCE_URLS.POLLSTER}/`,
      data: data,
      context_set: {
        pollsterId: "id",
      },
      context_get: {
        userId: "custom_user",
      },
      method: "POST",
    };
  }

  static updatePollsterTransaction(
    data: CreatePollster,
    id: number | string
  ): Transaction {
    return {
      url: `/${RESOURCE_URLS.POLLSTER}/${id}/`,
      data: data,
      context_set: {},
      context_get: {},
      method: "PATCH",
    };
  }
}
