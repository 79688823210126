import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import React from "react";

export { ContentContainer };

interface ContentContainerProps {
  children: React.ReactNode;
}

function ContentContainer({ children }: ContentContainerProps): JSX.Element {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        padding: "2rem 7vw",
        gap: "2rem",
      }}
    >
      {children}
    </div>
  );
}
