import DateFnsUtils from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { createTheme, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Login } from "pages/Login";
import { PollAssignmentPage } from "pages/PollAssignment";
import { PollPage } from "pages/Polls/PollPage";
import { PollRecordPage } from "pages/Polls/PollRecordPage";
import { PollRecordSearch } from "pages/Polls/PollRecordSearch";
import { PollResults } from "pages/Polls/PollResults";
import { PollSearch } from "pages/Polls/PollSearch";
import { PollsterPage } from "pages/Pollster/PollsterPage";
import { PollsterSearch } from "pages/Pollster/PollsterSearch";

export { App };

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#DD6B25",
      light: "#FF0",
      // dark: "#ff9b53",
      // dark: "#c66900",
      dark: "#b3571e",
      // dark: "#8f3700",
      // dark: "#ff7c2b",
    },
  },
});

function App(): JSX.Element {
  return (
    <>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/polls" element={<PollSearch />} />
              <Route
                path="/poll/:id/assignments"
                element={<PollAssignmentPage />}
              />
              <Route path="/poll/:id/answers" element={<PollRecordSearch />} />
              <Route path="/poll/:id/results" element={<PollResults />} />
              <Route
                path="/poll/:id/answers/:recordId"
                element={<PollRecordPage />}
              />
              <Route path="/poll/:id" element={<PollPage />} />
              <Route path="/pollsters" element={<PollsterSearch />} />
              <Route path="/pollster/:id" element={<PollsterPage />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
}
