import ClearIcon from "@mui/icons-material/Clear";
import { Box, IconButton, ListItem, Stack } from "@mui/material";
import { FieldArray } from "formik";
import React from "react";

export { ImageArray };

interface ImageArrayProps {
  name: string;
  choice: any;
}

function ImageArray({ name, choice }: ImageArrayProps): JSX.Element {
  return (
    <FieldArray name={name}>
      {(fieldArrayPropsImages) => {
        const { push, remove } = fieldArrayPropsImages;

        return choice?.images == undefined
          ? null
          : choice?.images?.map((image, imageIndex) => (
              <ListItem
                key={imageIndex}
                sx={{
                  justifyContent: "flex-start",
                  width: "auto",
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    overflowX: "auto",
                    paddingBottom: "2rem",
                    gap: "1rem",
                  }}
                >
                  <Box key={imageIndex}>
                    <Box
                      component="img"
                      src={image}
                      alt={"Imagen"}
                      sx={{
                        maxWidth: "200px",
                        maxHeight: "300px",
                        objectFit: "contain",
                      }}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                      onClick={() => {
                        remove(imageIndex);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Stack>
              </ListItem>
            ));
      }}
    </FieldArray>
  );
}
