import React from "react";

import { BasicApi, RESOURCE_URLS } from "common/api";
import { CreateUser, PageResponse, Transaction, User } from "common/apiTypes";

export { UserRepository };

class UserRepository {
  static async getUser(id: number) {
    return await BasicApi.getById<User>(RESOURCE_URLS.USER, id);
  }

  static async getUsers(searchParams?: URLSearchParams) {
    return await BasicApi.get<PageResponse<User>>(
      RESOURCE_URLS.USER,
      searchParams
    );
  }

  static async createUser(data: CreateUser): Promise<User | null> {
    const response = await BasicApi.post<CreateUser>(RESOURCE_URLS.USER, data);
    if (response == null) {
      return null;
    }
    return response.data as User;
  }

  static async deleteUser(id: number | string) {
    return await BasicApi.delete(RESOURCE_URLS.USER, id);
  }

  static createUserTransaction(data: CreateUser): Transaction {
    return {
      url: `/${RESOURCE_URLS.USER}/`,
      data: data,
      context_set: {
        userId: "id",
      },
      context_get: {},
      method: "POST",
    };
  }

  static updateUserTransaction(
    data: CreateUser,
    id: number | string
  ): Transaction {
    return {
      url: `/${RESOURCE_URLS.USER}/${id}/`,
      data: data,
      context_set: {},
      context_get: {},
      method: "PATCH",
    };
  }
}
