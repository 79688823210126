import { formatDate } from "../../common/utilities";
import { CreateButton } from "../../components/CreateButton";
import { ResultsCardGeneral } from "../../components/ResultsCard/ResultsCardGeneral";
import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import { Box, Button } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Base } from "common/Base";
import { CustomBreadCrumbs } from "common/BreadCrumbs";
import { ContentContainer } from "common/ContentContainer";
import { TabNames } from "common/NavBar";
import { NavBar } from "common/NavBar";
import { PageTitle } from "common/Titles";
import { BasicApi, RESOURCE_URLS } from "common/api";
import { Poll, PollGraphs, PollRecord } from "common/apiTypes";

import { InformationCard } from "components/InformationCard";

import { PollRepository } from "repositories/PollRepository";

export { PollResults };

function PollResults(): JSX.Element {
  const { id: pollId } = useParams();
  const [poll, setPoll] = useState<Poll | undefined>();
  const [pollGraphs, setPollGraphs] = useState<PollGraphs | undefined>();

  console.log(pollGraphs);

  const refreshPoll = async () => {
    if (pollId == undefined) {
      return;
    }
    const newPoll = await PollRepository.getPoll(pollId);
    if (newPoll == null) {
      return;
    }
    setPoll(newPoll);
  };

  const refreshPollGraphs = async () => {
    if (pollId == undefined) {
      return;
    }
    const newPollGraphs = await BasicApi.getById<PollGraphs>(
      RESOURCE_URLS.GRAPHS,
      pollId
    );
    if (newPollGraphs == null) {
      return;
    }
    setPollGraphs(newPollGraphs);
  };

  useEffect(() => {
    void refreshPoll();
    void refreshPollGraphs();
  }, [pollId]);

  const downloadExcel = async () => {
    await BasicApi.getExcel(RESOURCE_URLS.EXCEL, pollId ?? "-1");
  };

  return (
    <Base>
      <NavBar currentTab={TabNames.Encuestas} />
      <ContentContainer>
        <CustomBreadCrumbs
          breadcrumbs={[{ label: "Encuestas", targetUrl: "/polls" }]}
        />
        <PageTitle title={"Resultados de Encuesta"} />
        {poll != null && (
          <Button
            variant="contained"
            type="button"
            css={{
              alignSelf: "flex-start",
            }}
            onClick={downloadExcel}
          >
            Descargar Excel
          </Button>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InformationCard
            title="Información de la Encuesta"
            image={""}
            rows={[
              // {
              //   name: "Lugar",
              //   content: "Parindícuaro",
              // },
              {
                name: "Fecha",
                content:
                  poll?.creation_date != undefined
                    ? formatDate(new Date(poll.creation_date))
                    : formatDate(new Date()),
              },
              {
                name: "No. de Encuestadores",
                content: poll?.pollsters_amount,
              },
              {
                name: "No. de Respuestas",
                content: poll?.answers_amount,
              },
            ]}
          />
          {poll != undefined &&
            poll.questions.map((question, _index) => {
              return (
                <ResultsCardGeneral
                  key={question.id}
                  questionNumber={question.number}
                  questionTitle={question.text}
                  images={
                    pollGraphs == undefined ? [] : pollGraphs[question.number]
                  }
                />
              );
            })}
        </Box>
      </ContentContainer>
    </Base>
  );
}
