import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";

interface ResultsCardProps {
  questionNumber: number;
  questionTitle: string;
  children: React.ReactNode;
}

function ResultsCard({
  questionNumber,
  questionTitle,
  children,
}: ResultsCardProps): JSX.Element {
  const [cardCollapsed, setCardCollapsed] = useState(false);

  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: "2rem",
        width: "100%",
        borderRadius: "12px",
        padding: 0,
        border: "1px solid #fff",
        wordBreak: "normal",
        overflowWrap: "break-word",
        marginBottom: "2rem",
      }}
    >
      <CardHeader
        sx={{
          margin: 0,
          padding: "0.5rem 1rem",
          display: "flex",
          flexDirection: "row",
          flex: "1",
          backgroundColor: "#CA6121",
          "& .MuiCardHeader-title": {
            textAlign: "center",
          },
          "& .MuiCardHeader-action": {
            margin: 0,
          },
        }}
        avatar={
          <IconButton
            aria-label="collapse"
            onClick={() => {
              setCardCollapsed(!cardCollapsed);
            }}
          >
            <KeyboardArrowDown
              sx={{
                transform: cardCollapsed ? "rotate(0)" : "rotate(-90deg)",
                transition: "transform 1s",
              }}
            />
          </IconButton>
        }
        title={`#${questionNumber} - ${questionTitle}`}
        titleTypographyProps={{
          variant: "h5",
        }}
      />
      <Collapse in={!cardCollapsed} timeout={1000}>
        <CardContent
          sx={{
            padding: "2rem 4rem",
          }}
        >
          {children}
        </CardContent>
      </Collapse>
    </Card>
  );
}

export { ResultsCard };
