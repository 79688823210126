import { useGetPaginated } from "../../repositories/utils";
import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Formik, FormikProps } from "formik";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import { Base } from "common/Base";
import { CustomBreadCrumbs } from "common/BreadCrumbs";
import { ContentContainer } from "common/ContentContainer";
import { TabNames } from "common/NavBar";
import { NavBar } from "common/NavBar";
import { PageTitle } from "common/Titles";
import { Pollster } from "common/apiTypes";
import { CustomTextField } from "common/formUtilities";

import { CreateButton } from "components/CreateButton";
import { PaginatedNav } from "components/PaginatedNav";

import { PollsterRepository } from "repositories/PollsterRepository";

export { PollsterSearch };

const schema = yup.object().shape({
  name: yup.string().default(""),
  last_name: yup.string().default(""),
  phone: yup.string().default(""),
  address: yup.string().default(""),
  curp: yup.string().default(""),
  ine: yup.string().default(""),
});

interface FormValues {
  name: string;
  last_name: string;
  phone: string;
  address: string;
  curp: string;
  ine: string;
}

interface PollsterSearchFormProps {
  formikProps: FormikProps<any>;
}

function PollsterSearchForm({
  formikProps,
}: PollsterSearchFormProps): JSX.Element {
  return (
    <form
      onSubmit={formikProps.handleSubmit}
      css={{
        width: "100%",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <CustomTextField name="name" label="Nombre" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField name="last_name" label="Apellido" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField name="phone" label="Teléfono" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField name="address" label="Direccion" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField name="curp" label="CURP" />
        </Grid>
      </Grid>
      <Button variant="contained" type="submit" css={{ marginTop: "1rem" }}>
        Consultar
      </Button>
    </form>
  );
}

interface PollsterTableProps {
  pollsters: Pollster[];
}

function PollsterTable({ pollsters }: PollsterTableProps): JSX.Element {
  const navigate = useNavigate();
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          marginBottom: "2rem",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#DD6B25" }}>
            <TableRow>
              <TableCell align="center">Ver</TableCell>
              <TableCell align="center">Usuario</TableCell>
              <TableCell align="center">Nombre</TableCell>
              <TableCell align="center">Estado</TableCell>
              {/*<TableCell align="center">Última conexión</TableCell>*/}
            </TableRow>
          </TableHead>
          <TableBody>
            {pollsters.map((pollster) => (
              <TableRow
                key={pollster.id}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell align="center" sx={{ color: "#DD6B25" }}>
                  <IconButton
                    onClick={() => {
                      navigate(`/pollster/${pollster.id}`);
                    }}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  {pollster.custom_user.username}
                </TableCell>
                <TableCell align="center">
                  {pollster.name} {pollster.last_name}
                </TableCell>
                <TableCell align="center">Activo</TableCell>
                {/*<TableCell align="center">Today</TableCell>*/}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function PollsterSearch(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pollsters, refreshPollsters] = useGetPaginated<Pollster>(
    PollsterRepository.getPollsters,
    searchParams
  );

  const updateSearch = async (values: FormValues) => {
    const newSearchParams = new URLSearchParams(searchParams);
    for (const key of Object.keys(values)) {
      newSearchParams.set(key, `${(values as any)[key]}`);
    }
    setSearchParams(newSearchParams);
  };

  return (
    <Base>
      <NavBar currentTab={TabNames.Encuestadores} />
      <ContentContainer>
        <CustomBreadCrumbs
          breadcrumbs={[{ label: "Encuestadores", targetUrl: "/pollsters" }]}
        />
        <PageTitle title={"Consulta de Encuestadores"} />
        <CreateButton targetUrl={"/pollster/-1"}>
          Crear Encuestador
        </CreateButton>
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          onSubmit={updateSearch}
          initialValues={schema.getDefault()}
        >
          {(props) => <PollsterSearchForm formikProps={props} />}
        </Formik>
        <PollsterTable pollsters={pollsters.results} />
        <PaginatedNav
          page={pollsters.page}
          totalPages={pollsters.total_pages}
        />
      </ContentContainer>
    </Base>
  );
}
