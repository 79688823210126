import { TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";
import React from "react";

export { CustomTextField };

function CustomTextField(props: TextFieldProps) {
  const [field, meta, _helpers] = useField(props.name ?? "");
  return (
    <TextField
      id={props.name}
      css={{ width: "100%" }}
      variant="outlined"
      type="text"
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      {...props}
    />
  );
}
