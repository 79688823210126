import React from "react";

import { BasicApi, RESOURCE_URLS } from "common/api";
import { PageResponse, PollRecord } from "common/apiTypes";

export { PollRecordRepository };

class PollRecordRepository {
  static async getPollRecord(id: number | string) {
    return await BasicApi.getById<PollRecord>(RESOURCE_URLS.POLL_RECORD, id);
  }

  static async getPollRecords(searchParams?: URLSearchParams) {
    return await BasicApi.get<PageResponse<PollRecord>>(
      RESOURCE_URLS.POLL_RECORD,
      searchParams
    );
  }

  static async deletePollRecord(id: number | string) {
    return await BasicApi.delete(RESOURCE_URLS.POLL_RECORD, id);
  }
}
