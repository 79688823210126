export {
  downloadBlob,
  getCookie,
  mockValidate,
  fileToBase64,
  requestSubmitForm,
  formatDate,
  getFilenameFromContentDisposition,
};

function downloadBlob(blob: Blob, filename: string): void {
  const file_url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = file_url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function () {
    window.URL.revokeObjectURL(file_url);
  }, 1000);
}

function getCookie(name: string) {
  let cookieValue = "";
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function mockValidate(values: any) {
  const errors = {};
  return errors;
}

function fileToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function requestSubmitForm(formId: string): void {
  const form = document.getElementById(formId) as HTMLFormElement;
  form.requestSubmit();
}

const options = {
  year: "numeric",
  month: "long",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
  // second: "2-digit",
};

function formatDate(date: Date): string {
  const dateTimeFormat = new Intl.DateTimeFormat("es-MX", options);
  return dateTimeFormat.format(date);
}

function getFilenameFromContentDisposition(
  contentDisposition?: string | null
): string | undefined {
  if (contentDisposition == undefined) {
    return undefined;
  }
  const filename = contentDisposition.split("filename=")[1];
  if (filename != undefined && filename.at(0) === '"') {
    return filename.substring(1, filename.length - 1);
  }
  return filename;
}
