import { Box } from "@mui/material";
import React from "react";

import { ResultsCard } from "./ResultsCard";

interface ResultsCardGeneralProps {
  questionNumber: number;
  questionTitle: string;
  images: string[];
}

function ResultsCardGeneral({
  questionNumber = 0,
  questionTitle,
  images,
}: ResultsCardGeneralProps): JSX.Element {
  return (
    <ResultsCard questionNumber={questionNumber} questionTitle={questionTitle}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gap: "1rem",
          alignItems: "start",
          textAlign: "center",
        }}
      >
        {images.map((image) => {
          return (
            <Box
              key={image}
              component="img"
              src={image}
              alt="IMAGE"
              sx={{
                maxWidth: "400px",
                objectFit: "contain",
              }}
            />
          );
        })}
      </Box>
    </ResultsCard>
  );
}

export { ResultsCardGeneral };
