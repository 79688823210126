import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import React from "react";

import { ResultsCard } from "./ResultsCard";

import { Choice } from "common/apiTypes";

interface ChoiceMatrix {
  choiceItems: Choice[];
  choiceHeaders: Choice[];
}

interface ResultsCardIndividualProps {
  questionNumber: number;
  questionTitle: string;
  questionKind: string;
  selectedChoices: Map<number, number[]>;
  choiceVector?: Choice[];
  choiceMatrix?: ChoiceMatrix;
}

function ResultsCardIndividual({
  questionNumber = 0,
  questionTitle,
  questionKind,
  selectedChoices,
  choiceVector,
  choiceMatrix,
}: ResultsCardIndividualProps): JSX.Element {
  return (
    <ResultsCard questionNumber={questionNumber} questionTitle={questionTitle}>
      {/* FIXME Get these values from the enum in the model */}
      {questionKind == "SV" || questionKind == "MV" || questionKind == "RN" ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {choiceVector?.map((choiceItem) => {
                  return (
                    <TableCell align="center" key={choiceItem.id}>
                      {choiceItem.text}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {choiceVector?.map((choiceItem) => {
                  return (
                    <TableCell align="center" key={choiceItem.id}>
                      {selectedChoices?.get(0)?.includes(choiceItem.number) ? (
                        <FiberManualRecordIcon
                          fontSize="large"
                          sx={{ color: "#CA6121" }}
                        />
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                {choiceMatrix?.choiceItems.map((choiceItem) => {
                  return (
                    <TableCell align="center" key={choiceItem.id}>
                      {choiceItem.text}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {choiceMatrix?.choiceHeaders?.map((choiceHeader) => (
                <TableRow
                  key={choiceHeader.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    // sx={{ color: "#DD6B25" }}
                  >
                    {choiceHeader.text}
                  </TableCell>
                  {choiceMatrix?.choiceItems.map((choiceItem) => {
                    return (
                      <TableCell align="center" key={choiceItem.id}>
                        {selectedChoices.has(choiceHeader.number) ? (
                          selectedChoices
                            ?.get(choiceHeader.number)
                            ?.includes(choiceItem.number) ? (
                            <FiberManualRecordIcon
                              fontSize="large"
                              sx={{ color: "#CA6121" }}
                            />
                          ) : null
                        ) : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </ResultsCard>
  );
}

export { ResultsCardIndividual };
