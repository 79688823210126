import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { centeredFlex } from "common/styles";

interface NavigationTabProps {
  isActive: boolean;
  targetUrl: string;
  children: React.ReactNode;
}

function NavigationTab({
  isActive,
  targetUrl,
  children,
}: NavigationTabProps): JSX.Element {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => {
    navigate(targetUrl);
  }, [navigate, targetUrl]);

  return (
    <div
      onClick={handleOnClick}
      css={[
        centeredFlex,
        {
          padding: "0.75rem 1rem",
          ...(isActive
            ? {
                backgroundColor: "#363435",
                borderRadius: "20px 20px 0px 0px",
                color: "primary.main",
              }
            : {}),
          "& svg": {
            marginRight: "0.5rem",
          },
        },
      ]}
    >
      {children}
    </div>
  );
}
export { NavigationTab };
