import axios, { AxiosResponse } from "axios";

import {
  downloadBlob,
  getCookie,
  getFilenameFromContentDisposition,
} from "common/utilities";

export { BasicApi, RESOURCE_URLS };

const RESOURCE_URLS = {
  LOGIN: "api/login",
  EXCEL: "api/poll_excel",
  GRAPHS: "api/survey_graphs",
  POLL_ASSIGNMENT: "api/poll_assignment",
  POLLSTER: "api/pollster",
  QUESTION: "api/question",
  CHOICE: "api/choice",
  POLL: "api/poll",
  POLL_RECORD: "api/poll_record",
  USER: "api/user",
  TRANSACTION: "api/transaction",
};

class BasicApi {
  static async getById<Type>(
    resourceUrl: string,
    id: string | number
  ): Promise<Type | null> {
    const url = `/${resourceUrl}/${id}/`;
    const csrfToken = getCookie("csrftoken");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
    };
    try {
      const response = await axios.get(url, config);
      if (response.status !== 200) {
        return null;
      }
      return response.data;
    } catch (error) {
      return error.response ?? null;
    }
  }

  static async getFirst<Type>(
    resourceUrl: string,
    searchParams?: URLSearchParams
  ): Promise<Type | null> {
    const url = `/${resourceUrl}/?${searchParams?.toString() ?? ""}`;
    const csrfToken = getCookie("csrftoken");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
    };
    try {
      const response = await axios.get(url, config);
      if (response.status !== 200) {
        return null;
      }
      const result: Type[] = response.data;
      if (result.length > 0) {
        return result[0];
      } else {
        return null;
      }
    } catch (error) {
      return error.response ?? null;
    }
  }

  static async get<Type>(
    resourceUrl: string,
    searchParams?: URLSearchParams
  ): Promise<Type | null> {
    const url = `/${resourceUrl}/?${searchParams?.toString() ?? ""}`;
    const csrfToken = getCookie("csrftoken");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
    };
    const response = await axios.get(url, config);
    if (response.status !== 200) {
      return null;
    }
    return response.data;
  }

  static async post<Type>(
    resourceUrl: string,
    data: Type
  ): Promise<AxiosResponse | null> {
    const url = `/${resourceUrl}/`;
    const csrfToken = getCookie("csrftoken");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
    };
    try {
      return await axios.post(url, data, config);
    } catch (error) {
      return error.response ?? null;
    }
  }

  static async patch<Type>(
    resourceUrl: string,
    id: string | number,
    data: Type
  ): Promise<AxiosResponse | null> {
    const url = `/${resourceUrl}/${id}/`;
    const csrfToken = getCookie("csrftoken");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
    };
    try {
      return await axios.patch(url, data, config);
    } catch (error) {
      return error.response ?? null;
    }
  }

  static async delete(
    resourceUrl: string,
    id: string | number // FIXME Fix return type // : Promise<Response>
  ) {
    const url = `/${resourceUrl}/${id}/`;
    const csrfToken = getCookie("csrftoken");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
    };
    try {
      return await axios.delete(url, config);
    } catch (error) {
      return error.response ?? null;
    }
  }

  static async getExcel(
    resourceUrl: string,
    id: string | number
    // searchParams?: URLSearchParams
  ): Promise<boolean> {
    const url = `/${resourceUrl}/${id}/`;
    // const url = `${resourceUrl}/?${searchParams?.toString() ?? ""}`;
    const csrfToken = getCookie("csrftoken");
    const config = {
      headers: {
        // "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      responseType: "blob",
    };
    const response = await axios.get(url, config);
    if (response.status !== 200) {
      return false;
    }
    const contentDisposition = response.headers["content-disposition"];
    const contentType = response.headers["content-type"];
    const response_filename =
      getFilenameFromContentDisposition(contentDisposition);
    if (
      contentType == undefined ||
      !contentType.startsWith(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    ) {
      return false;
    }
    const data = await response.data;
    const blob = new Blob([data]);
    downloadBlob(blob, response_filename ?? "download.xlsx");
    return true;
  }
}
