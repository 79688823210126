import { css } from "@emotion/react";
import { jsx } from "@emotion/react";
import { Button, Grid } from "@mui/material";
import { Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import { Base } from "common/Base";
import { CustomBreadCrumbs } from "common/BreadCrumbs";
import { ContentContainer } from "common/ContentContainer";
import { TabNames } from "common/NavBar";
import { NavBar } from "common/NavBar";
import { PageTitle } from "common/Titles";
import { BasicApi, RESOURCE_URLS } from "common/api";
import { CreatePollster, CreateUser, Pollster } from "common/apiTypes";
import { CustomTextField } from "common/formUtilities";

import { PollsterRepository } from "repositories/PollsterRepository";
import { UserRepository } from "repositories/UserRepository";
import { useGetById } from "repositories/utils";

export { PollsterPage };

const schema = yup.object().shape({
  name: yup.string().default("").required(),
  last_name: yup.string().default("").required(),
  phone: yup.string().default("").required(),
  address: yup.string().default("").required(),
  curp: yup.string().default("").required(),
  ine: yup.string().default(""),
  username: yup.string().default("").required(),
});

interface FormValues {
  name: string;
  last_name: string;
  phone: string;
  address: string;
  curp: string;
  ine: string;
  username: string;
}

interface PollsterFormProps {
  formikProps: FormikProps<any>;
  mode: "create" | "update";
}

function PollsterForm({ formikProps, mode }: PollsterFormProps): JSX.Element {
  return (
    <form
      onSubmit={formikProps.handleSubmit}
      css={{
        width: "100%",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <CustomTextField name="name" label="Nombre" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField name="last_name" label="Apellido" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField name="phone" label="Teléfono" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField name="address" label="Direccion" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField name="curp" label="CURP" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField name="username" label="Usuario" />
        </Grid>
      </Grid>
      <Button variant="contained" type="submit" css={{ marginTop: "1rem" }}>
        {mode === "create" ? "Crear" : "Modificar"}
      </Button>
    </form>
  );
}

function PollsterPage(): JSX.Element {
  const { id: pollsterId } = useParams();
  const [pollster, refreshPollster] = useGetById<Pollster>(
    PollsterRepository.getPollster,
    pollsterId ?? -1
  );
  const [initialValues, setInitialValues] = useState<FormValues>(
    schema.getDefault()
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (pollster != undefined) {
      setInitialValues({
        name: pollster.name,
        last_name: pollster.last_name,
        phone: pollster.phone,
        address: pollster.address,
        curp: pollster.curp,
        ine: "",
        username: pollster.user.username,
      });
    }
  }, [pollster]);

  const createPollster = async (values: FormValues) => {
    const { username, ine, ...restValues } = values;
    const userCreateData: CreateUser = {
      username: username,
      email: "",
      password: "123456",
      kind: "pollster",
    };
    const pollsterCreateData: CreatePollster = {
      ...restValues,
      status: "active",
      custom_user: -1,
    };
    const transactionData = [
      UserRepository.createUserTransaction(userCreateData),
      PollsterRepository.createPollsterTransaction(pollsterCreateData),
    ];
    const response = await BasicApi.post(
      RESOURCE_URLS.TRANSACTION,
      transactionData
    );
    console.log(response);
    if (response != null && response.status === 200) {
      const newId = response.data.pollsterId;
      if (newId != undefined) {
        navigate(`/pollster/${newId}`);
      }
    }
  };

  const updatePollster = async (values: FormValues) => {
    if (pollster == null) {
      return;
    }
    const { username, ine, ...restValues } = values;
    const userData: CreateUser = {
      username: username,
      email: "",
      password: "123456",
      kind: "pollster",
    };
    const pollsterData: CreatePollster = {
      ...restValues,
      status: "active",
      custom_user: pollster.custom_user,
    };
    const transactionData = [
      UserRepository.updateUserTransaction(userData, pollster.user.id),
      PollsterRepository.updatePollsterTransaction(pollsterData, pollster.id),
    ];
    const response = await BasicApi.post(
      RESOURCE_URLS.TRANSACTION,
      transactionData
    );
    console.log(response);
    if (response != null && response.status === 200) {
      await refreshPollster();
    }
  };

  const deletePollster = async () => {
    if (pollster == null) {
      return;
    }
    await UserRepository.deleteUser(pollster.user.id);
  };

  return (
    <Base>
      <NavBar currentTab={TabNames.Encuestadores} />
      <ContentContainer>
        <CustomBreadCrumbs
          breadcrumbs={[{ label: "Encuestadores", targetUrl: "/pollsters" }]}
        />
        <PageTitle
          title={pollster == null ? "Crear Encuestador" : "Encuestador"}
        />
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          onSubmit={async (values) => {
            if (pollster == null) {
              await createPollster(values);
            } else {
              await updatePollster(values);
            }
          }}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {(props) => (
            <PollsterForm
              formikProps={props}
              mode={pollster == null ? "create" : "update"}
            />
          )}
        </Formik>
        {pollster != null && (
          <Button
            variant="contained"
            color="error"
            type="submit"
            onClick={async () => {
              await deletePollster();
              navigate("/pollsters", { replace: true });
            }}
            css={{ alignSelf: "flex-start" }}
          >
            Eliminar
          </Button>
        )}
      </ContentContainer>
    </Base>
  );
}
