import React from "react";

import { BasicApi, RESOURCE_URLS } from "common/api";
import {
  CreateChoice,
  PageResponse,
  Choice,
  Transaction,
} from "common/apiTypes";

export { ChoiceRepository };

class ChoiceRepository {
  static async getChoice(id: number | string) {
    return await BasicApi.getById<Choice>(RESOURCE_URLS.CHOICE, id);
  }

  static async getChoices(searchParams?: URLSearchParams) {
    return await BasicApi.get<PageResponse<Choice>>(
      RESOURCE_URLS.CHOICE,
      searchParams
    );
  }

  static async createChoice(data: CreateChoice): Promise<Choice | null> {
    const response = await BasicApi.post<CreateChoice>(
      RESOURCE_URLS.CHOICE,
      data
    );
    if (response == null) {
      return null;
    }
    return response.data as Choice;
  }

  static createChoiceTransaction(data: CreateChoice): Transaction {
    return {
      url: `/${RESOURCE_URLS.CHOICE}/`,
      data: data,
      context_set: {},
      context_get: {
        questionId: "question",
      },
      method: "POST",
    };
  }

  static updateChoiceTransaction(data: CreateChoice): Transaction {
    return {
      url: `/${RESOURCE_URLS.CHOICE}/`,
      data: data,
      context_set: {},
      context_get: {},
      method: "PATCH",
    };
  }
}
